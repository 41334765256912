import React from 'react';
import PropTypes from 'prop-types';

export function CoverSpreadPeacockAvatar({
  className = null,
  alt = null,
}) {
  return (
    <svg alt={alt} className={className} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="14" cy="14" r="14" fill="none" />
      <path d="M18.0825 9.79415L15.266 16.2227L14.4633 10.6359C14.3603 9.87584 15.6531 9.40702 15.6531 9.40702C15.5359 9.14419 15.1274 9.14774 15.1274 9.14774H14.2537C14.1152 7.77323 15.3618 6.77165 16.7079 7.0771C18.0327 7.37189 18.5122 8.81033 18.0825 9.78705" fill="#6E55DC" />
      <path d="M12.8509 18.304L7.36349 14.6457C5.96767 13.7116 4.58606 14.6635 4.29127 15.6473C3.85797 17.0964 4.82758 18.304 6.34415 18.304H12.8509Z" fill="#FCCC12" />
      <path d="M13.5715 9.24013C13.774 7.81945 12.5024 6.7717 11.1244 7.0807C9.7996 7.37549 9.32012 8.81748 9.74988 9.79064L12.5664 16.2192L13.5715 9.23658V9.24013Z" fill="#EF1541" />
      <path d="M12.3421 17.2777L9.63926 11.1226C9.00706 9.69479 7.33065 9.51721 6.40721 10.3554C5.75725 10.945 5.24936 12.5539 6.70556 13.5235L12.3385 17.2777H12.3421Z" fill="#FF7112" />
      <path d="M21.4846 18.3039C23.0012 18.3039 23.9708 17.0963 23.5375 15.6472C23.2462 14.6634 21.8646 13.7151 20.4688 14.6456L14.9814 18.3039H21.4846Z" fill="#05AC3F" />
      <path d="M21.1262 13.5235C22.5824 12.5504 22.078 10.945 21.4245 10.3554C20.4975 9.51721 18.8247 9.69479 18.1925 11.1226L15.4932 17.2777L21.1262 13.5235Z" fill="#069DE0" />
    </svg>
  );
}

CoverSpreadPeacockAvatar.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
};

