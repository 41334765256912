import get from 'lodash.get';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Head from 'next/head';

import {
  person as PersonPropType,
} from 'lib/CustomPropTypes';
import { reduceCSL } from 'lib/arrayUtils';
import getWorkbenchByline from 'lib/getWorkbenchByline';
import { LinkIfHref } from 'components/Link';
import './styles.themed.scss';

/* Important: Making changes to this file could have an impact on shorthand articles */

const block = 'byline-name';
export const BylineName = ({
  position,
  person = {},
  showJobTitle = false,
  additionalClasses = '',
}) => {
  const name = get(person, 'name', null);
  if (!name) {
    return null;
  }

  const url = get(person, ['url', 'primary'], null);
  const nameTrim = name.trim();

  const jobTitle = get(person, ['jobTitle'], null);
  const title = jobTitle ? jobTitle.reduce(reduceCSL, []) : '';

  let authorContent = (
    <span className={classNames(block, additionalClasses)} data-test={block} data-testid={block}>
      <LinkIfHref href={url}>
        {nameTrim}
      </LinkIfHref>
      { (showJobTitle && jobTitle.length > 0) && (
        <>
          {', '}
          {title}
        </>
      )}
    </span>
  );

  if (name.startsWith('<component')) {
    authorContent = getWorkbenchByline(name);
  }

  return (
    <>
      <Head>
        <meta name={`branch:deeplink:authorName${position}`} content={nameTrim} />
      </Head>

      { authorContent }
    </>
  );
};

BylineName.propTypes = {
  person: PersonPropType,
  showJobTitle: PropTypes.bool,
  additionalClasses: PropTypes.string,
  position: PropTypes.number.isRequired,
};

